import "bootstrap";
import "./sass/main.scss";

var Isotope = require('isotope-layout');

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(document).scrollTop() > 50) {
      $("nav").addClass("shrink");
    } else {
      $("nav").removeClass("shrink");
    }
  });
});

var grid = document.querySelector(".grid");

var gridIso = new Isotope(grid, {
  itemSelector: ".grid-item", // use a separate class for itemSelector, other than .col-
  percentPosition: true,
  masonry: {
    columnWidth: ".grid-sizer"
  }
});

$(".filter-button-group").on("click", "button", function() {
  var filterValue = $(this).attr("data-filter");
  gridIso.arrange({ filter: filterValue });
  $('.filter-button-group .btn').removeClass('active');
  $(this).addClass('active');
});
